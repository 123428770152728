<template>
  <div
      ref="container"
      class="form-field-large input-field-container"
      :style="fieldStyle"
      @click="openDatePicker"
  >
    <div style="display: flex; padding: 7px 11px; gap: 8px; height: 32px;" v-if="fromDate">
      <img
          width="16"
          height="16"
          style="display: block"
          :src="require(`@/assets/images/` + 'calendar_20_20.svg')"
          alt=""
      />
      <input
          :type="'date'"
          ref="input"
          class="datepicker-input"
          v-model="createdFromDate"
      />
    </div>
    <div style="display: flex; padding: 7px 11px; gap: 8px; height: 32px" v-if="toDate">
      <img
          width="16"
          height="16"
          style="display: block"
          :src="require(`@/assets/images/` + 'calendar_20_20.svg')"
          alt=""
      />
      <input
          :type="'date'"
          ref="input"
          class="datepicker-input"
          v-model="createdToDate"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "BaseDropdown",
  props: ["fromDate", "toDate", "selectedFromDate", "selectedToDate"],
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      fieldType: "dropdown",
      createdFromDate: "",
      createdToDate: "",
      fieldPlaceholder: "",
      fieldRequired: false,
      fieldSize: "large",
      fieldClass: "form-field-large",
      fieldStyle: {
        border: "1px solid #E6E8EC",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        gap: "12px",
        background: "white",
        cursor: "pointer",
      },
    };
  },
  watch: {
    createdFromDate() {
      console.log("FIELD VALUE", this.createdFromDate);
      this.$emit("dateFromChosen", this.createdFromDate);
    },
    createdToDate() {
      console.log("FIELD VALUE", this.createdToDate);
      this.$emit("dateToChosen", this.createdToDate);
    },
    selectedFromDate() {
      this.createdFromDate = this.selectedFromDate;
    },
    selectedToDate() {
      this.createdToDate = this.selectedToDate;
    }
  },
  mounted() {
    if (this.selectedFromDate) {
      this.createdFromDate = this.selectedFromDate;
    }
    if (this.selectedToDate) {
      this.createdToDate = this.selectedToDate;
    }
  },
  methods: {
    openDatePicker() {
      this.$refs.input.showPicker()
    }
  },
};
</script>

<style scoped>
.form-field-large input[type="date"]:after {
  content: "";
}

.form-field-large input::-webkit-datetime-edit-fields-wrapper {
  position: relative;
}

.form-field-large {
  height: 32px !important;
  width: 136px !important;
  gap: 4px !important;
}

.form-field-large input[type="date"]:after {
  content: "";
}

.input-field-container {
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
}

.form-field-large input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: transparent;
  background: transparent;
  cursor: pointer;
}

.datepicker-input {
  z-index: 1;
  width: 88px;
  font-family: Inter, sans-serif;
  font-size: 12px;
  line-height: 16px;
}
</style>